import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Affix } from "antd";
import { useSelector } from "react-redux";
import { CkIcon } from "../../../CkUI/components";
import { getNavigationSelectedKeys } from "../../../stores";
import { gtmSetId } from "../../../utilities";
import "./styles.css";

interface IProps {}

const MobileNavMenu: React.FC<IProps> = ({}) => {
  const selectedKeys = useSelector(getNavigationSelectedKeys);
  const [currentKey, setCurrentKey] = React.useState<string>("");

  useEffect(() => {
    if (selectedKeys && selectedKeys.length > 0) {
      setCurrentKey(selectedKeys[0]);
    }
  }, [selectedKeys]);

  const getLinkStyle = (key: string) => {
    if (currentKey === key) {
      return "menu-item selected";
    }
    return "menu-item";
  };

  return (
    <Affix className="mobile-nav-menu-affix" offsetBottom={0} style={{ zIndex: 500 }}>
      <div className="mobile-nav-menu">
        <div>
          <Link className={getLinkStyle("INICIO")} to="/">
            <CkIcon
              name="home"
              aria-label="Inicio"
              width=""
              height=""
              fill=""
            />
            Inicio
          </Link>
          <Link id="HEADER023" onClick={(ev) => gtmSetId(ev.currentTarget)} className={getLinkStyle("VEHICULOS")} to="/vehiculos">
            <CkIcon
              name="vehicle"
              aria-label="Vehículos"
              width=""
              height=""
              fill=""
            />
            Vehículos
          </Link>
          <Link id="HEADER024" onClick={(ev) => gtmSetId(ev.currentTarget)} className={getLinkStyle("CONSULTAS")} to="/chats">
            <CkIcon
              name="chat"
              aria-label="Chats"
              width=""
              height=""
              fill=""
            />
            Chats
          </Link>
          <Link id="HEADER025" onClick={(ev) => gtmSetId(ev.currentTarget)} className={getLinkStyle("CITAS")} to="/citas">
            <CkIcon
              name="calendar-add"
              aria-label="Citas"
              width=""
              height=""
              fill=""
            />
            Citas
          </Link>
        </div>
        
      </div>
    </Affix>
  );
};

export default MobileNavMenu;
