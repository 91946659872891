import React, { FC, useEffect, useState, memo } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useQuery, useIsMobileScreen } from "../../../utilities";
import { ConfigurationsAPI } from "../../../services/axios";
import {
  getUser,
  logoutUser,
  setNavigationSelectedKeys,
} from "../../../stores";
import DesktopNavbar from "./DesktopNavbar";
import MobileNavbar from "./MobileNavbar";
import LoginModal from "./LoginModal";
import SignUpModal from "./SignUpModal";
import ChangePasswordModal from "./ChangePasswordModal";
import "./styles.css";

interface IProps {}

const AppNavbar: FC<IProps> = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const query = useQuery();
  const location = useLocation();
  const [changePasswordModal, setChangePasswordModal] =
    useState<boolean>(false);
  const [loginModal, setLoginModal] = useState<boolean>(false);
  const [signupModal, setSignupModal] = useState<boolean>(false);
  const [sasToken, setSasToken] = useState<string>();
  const [guestAppointmentCode, setGuestAppointmentCode] = useState<string>("");
  const isMobile = useIsMobileScreen(1200);

  const setSelectedKeys = (keys: string[]) =>
    dispatch(setNavigationSelectedKeys(keys));

  const scrollTo = (containerQuery: string = ".ant-layout") => {
    const pageContainer = document.querySelector(containerQuery);
    pageContainer && pageContainer.scrollIntoView();
  };

  const triggerAntLayoutScroll = (visible: boolean) => {
    var navLayout =
      document.documentElement.getElementsByClassName("ant-layout-header");
    var antLayout =
      document.documentElement.getElementsByClassName("ant-layout");

    antLayout[0].classList.remove("disable-scroll-div");
    navLayout[0].classList.remove("ant-layout-header-in-drawer");
  };

  const logout = () => dispatch(logoutUser());

  useEffect(() => {
    const action = query.get("action");
    if (user) {
      if (action === "logout") logout();
      setLoginModal(false);
      setSignupModal(false);
      ConfigurationsAPI.getSasToken().then((values) => {
        setSasToken(values.data);
      });
    } else {
      if (location.pathname === "/campaña") return;
      if (action === "login") {
        setLoginModal(true);
      } else if (action === "signup") {
        setSignupModal(true);
      }
    }
    return () => {};
  }, [user]);

  useEffect(() => {
    if (location.pathname) {
      if (location.pathname === "/") setSelectedKeys(["INICIO"]);
      else if (location.pathname.includes("blog")) setSelectedKeys(["blog"]);
      else if (location.pathname.includes("vehiculos"))
        setSelectedKeys(["VEHICULOS"]);
      else if (location.pathname.includes("chats"))
        setSelectedKeys(["CONSULTAS"]);
      else if (
        location.pathname.includes("citas") ||
        location.pathname.includes("cita")
      )
        setSelectedKeys(["CITAS"]);
      else if (
        location.pathname.includes("quienes-somos") ||
        location.pathname.includes("talleres-afiliados") ||
        location.pathname.includes("preguntas-frecuentes")
      )
        setSelectedKeys(["NOSOTROS"]);
      else setSelectedKeys([]);
    }
  }, [location.pathname]);

  useEffect(() => {
    scrollTo();
  }, []);

  useEffect(() => {
    if (guestAppointmentCode && guestAppointmentCode !== "") {
      window.location.href = `/cita/${guestAppointmentCode}`;
    }
  }, [guestAppointmentCode]);

  return (
    <>
      {isMobile ? (
        <MobileNavbar
          user={user}
          sasToken={sasToken}
          setLoginModal={setLoginModal}
          setSignupModal={setSignupModal}
          logout={logout}
          triggerAntLayoutScroll={triggerAntLayoutScroll}
          setGuestAppointmentCode={setGuestAppointmentCode}
        />
      ) : (
        <DesktopNavbar
          user={user}
          sasToken={sasToken}
          setLoginModal={setLoginModal}
          setSignupModal={setSignupModal}
          logout={logout}
          triggerAntLayoutScroll={triggerAntLayoutScroll}
          setGuestAppointmentCode={setGuestAppointmentCode}
        />
      )}
      {!!user === false && (
        <LoginModal
          setChangePasswordModal={setChangePasswordModal}
          setSignupModal={setSignupModal}
          setLoginModal={setLoginModal}
          loginModal={loginModal}
        />
      )}
      {!!user === false && (
        <SignUpModal
          setSignupModal={setSignupModal}
          signupModal={signupModal}
        />
      )}
      {!!user === false && (
        <ChangePasswordModal
          setChangePasswordModal={setChangePasswordModal}
          changePasswordModal={changePasswordModal}
        />
      )}
    </>
  );
};

export default memo(AppNavbar);
